import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import "./i18n";
import { store } from "./state/store";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV || "not_set",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.25, // 1.0:Capture 100% of transactions (adjust as needed)
  replaysSessionSampleRate: 0.1,  // Capture 10% of user sessions
  replaysOnErrorSampleRate: 1.0,  // Capture 100% of sessions when an error occurs
});

createRoot(document.getElementById("root")).render(
    <Provider store={store}>
        <App />
    </Provider>
);
